import { Button, Form, Input, message } from "antd";
import React from "react";

function ActualizaUsuario(props) {
  const [actualizando, setActualizando] = React.useState(false);
  React.useEffect(() => {
    console.log(props.usuario, "El usuario");
  }, [props]);

  async function actualizarEmail(values) {
    setActualizando(true);
    if (values.email !== values.confirmaremail) {
      return message.error("Los emails no coinciden");
    }
    if (values.email === props.usuario.email) {
      return message.error("El email es el mismo que tiene actualmente");
    }

    const res = await fetch(`https://vcopgg4k05.execute-api.us-east-1.amazonaws.com/prod/api/usuarios/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      body: JSON.stringify({
        _id: props.usuario._id,
        email: values.email,
        funcion: "actualizarEmail",
      }),
    });

    const data = await res.json();
    if (!data.success) {
      message.error(data.msg);
      setActualizando(false);
    } else {
      message.success("Email actualizado");
      props.setUsuarios([]);
      props.obtenerUsuarios(props.usuario.usuario);
      props.setActualizarUsuario(false);
      setActualizando(false);
    }
  }
  return (
    <>
      <h1>Actualizar Email</h1>
      <hr />
      <div style={{ display: "flex", flexDirection: "column" }}>
        <span>
          <strong>Nombre:</strong> {props.usuario.nombre}
        </span>
        <span>
          <strong>Usuario:</strong> {props.usuario.usuario}
        </span>
        <span>
          <strong>Email:</strong> {props.usuario.email}
        </span>
      </div>

      <Form style={{ marginTop: 20 }} onFinish={actualizarEmail}>
        <Form.Item name="email">
          <Input placeholder="Nuevo Email" />
        </Form.Item>
        <Form.Item name="confirmaremail">
          <Input placeholder="Confirmar Email" />
        </Form.Item>
        <Button htmlType="submit" type="primary" loading={actualizando}>
          Actualizar Email
        </Button>
      </Form>
    </>
  );
}

export default ActualizaUsuario;
