import {
  Checkbox,
  DatePicker,
  Input,
  InputNumber,
  Radio,
  Select,
  Switch,
} from "antd";
import React from "react";
import { Controller } from "react-hook-form";
import moment from "moment";

const { RangePicker } = DatePicker;

const InputT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <>
            <Input
              type={props.type}
              key={props.key}
              hidden={props.hidden}
              style={props.style}
              value={value}
              placeholder={props.placeholder}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
            />
          </>
        );
      }}
    />
  );
};
const InputNumberT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <>
            <InputNumber
              key={props.key}
              hidden={props.hidden}
              style={props.style}
              min={props.min}
              max={props.max}
              value={value}
              placeholder={props.placeholder}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
            />
          </>
        );
      }}
    />
  );
};
const TextAreaT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <>
            <Input.TextArea
              key={props.key}
              hidden={props.hidden}
              style={props.style}
              value={value}
              placeholder={props.placeholder}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
            />
          </>
        );
      }}
    />
  );
};
const CheckboxT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <Checkbox
            key={props.key}
            hidden={props.hidden}
            checked={value}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e);
              }
              onChange(e);
            }}
          />
        );
      }}
    />
  );
};
const SwitchT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <Switch
            key={props.key}
            hidden={props.hidden}
            valuePropName="checked"
            checked={value}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e);
              }
              onChange(e);
            }}
          />
        );
      }}
    />
  );
};
const RadioGroupT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <Radio.Group
            hidden={props.hidden}
            value={value}
            onChange={(e) => {
              if (props.onChange) {
                props.onChange(e);
              }
              onChange(e);
            }}
          >
            {props.children}
          </Radio.Group>
        );
      }}
    />
  );
};
const RadioT = (props) => {
  return <Radio key={props.key} hidden={props.hidden} value={props.valor} />;
};

const SelectT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { value, onChange } }) => {
        return (
          <>
            <Select
              key={props.key}
              hidden={props.hidden}
              placeholder={props.placeholder}
              style={props.style}
              value={value}
              onChange={(e, x) => {
                if (props.onChange) {
                  props.onChange(e, x);
                }
                onChange(e, x);
              }}
              showSearch={props.showSearch || false}
              mode={props.mode}
              defaultValue={props.defaultValue}
            >
              {props.children}
            </Select>
          </>
        );
      }}
    />
  );
};
const OptionT = (props) => {
  return (
    <>
      <Select.Option key={props.key} hidden={props.hidden} value={props.value}>
        {props.children}
      </Select.Option>
    </>
  );
};
const DatePickerT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { onChange, value } }) => {
        return (
          <>
          {value ? (
          <>
          <DatePicker
              key={props.key}
              style={props.style}
              value={moment(value)}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
              picker={props.picker}
            />
          </>
          ) : (
          <>
          <DatePicker
              key={props.key}
              style={props.style}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
              picker={props.picker}
            />
          </>
          )
          }
            
          </>
        );
      }}
    />
  );
};
const RangePickerT = (props) => {
  return (
    <Controller
      {...props}
      render={({ field: { onChange, value } }) => {
        return (
          <>
          {value ? (
          <>
          <RangePicker
              value={[moment(value[0]), moment(value[1])]}
              style={props.style}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
            />
          </>
          ) : (
          <>
          <RangePicker
              style={props.style}
              onChange={(e) => {
                if (props.onChange) {
                  props.onChange(e);
                }
                onChange(e);
              }}
            />
          </>
          )
          }
            
          </>
        );
      }}
    />
  );
};

export {
  InputT,
  InputNumberT,
  CheckboxT,
  SwitchT,
  RadioGroupT,
  RadioT,
  SelectT,
  OptionT,
  DatePickerT,
  RangePickerT,
  TextAreaT,
};
