import React from "react";
import "./App.css";
import {
  Route,
  Link,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Login from "./paginas/login";
import Usuarios from "./paginas/usuarios";

function App() {
  let location = useLocation();
  let navigate = useNavigate();

  const verificarLogin = () => {
    const token = localStorage.getItem("token");

    if (!token && location.pathname !== "/login") {
      navigate("/login");
    }
  };

  React.useEffect(() => {
    verificarLogin();
  }, [location]);

  return (
    <>
      <Routes>
        <Route path="/" element={<Usuarios />} />
        <Route path="/login" element={<Login />} />
      </Routes>
    </>
  );
}

export default App;
