import { Button, Form, Input, message } from "antd";
import { useNavigate } from "react-router-dom";

function Login() {
  const navigate = useNavigate();
  const loguear = (values) => {
    console.log(values);

    // fetch post
    fetch("https://vcopgg4k05.execute-api.us-east-1.amazonaws.com/prod/api/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((data) => {
        if (data.token) {
          localStorage.setItem("token", data.token);
          message.success("Bienvenido");
          navigate("/");
        } else {
          message.error(data.msg);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        justifyContent: "center",
        alignItems: "center",
        paddingTop: 50
      }}
    >
      <div>LOGIN</div>
      <hr />
      <Form onFinish={loguear}>
        <Form.Item
          rules={[{ required: true, message: "Campo obligatorio" }]}
          name="user"
        >
          <Input placeholder="Usuario" />
        </Form.Item>
        <Form.Item
          rules={[{ required: true, message: "Campo obligatorio" }]}
          name="password"
        >
          <Input type="password" placeholder="Contraseña" />
        </Form.Item>
        <Button htmlType="submit" type="primary">
          Ingresar
        </Button>
      </Form>
    </div>
  );
}

export default Login;
