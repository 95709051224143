import { Button, Drawer, Input, message, Popconfirm, Table } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import ActualizarEmail from "../Login/ActualizarEmail";
import ActualizaUsuario from "./ActualizaUsuario";

const { Search } = Input;

function Usuarios() {
  const [buscando, setBuscando] = React.useState(false);
  const [usuarios, setUsuarios] = React.useState([]);
  const [actualizarUsuario, setActualizarUsuario] = React.useState(false);
  const [usuarioActual, setUsuarioActual] = React.useState({});
  const navigate = useNavigate();

  const columns = [
    {
      title: "Usuario",
      dataIndex: "usuario",
      key: "usuario",
    },

    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Nombre",
      dataIndex: "nombre",
      key: "nombre",
    },

    {
      title: "",
      dataIndex: "actualizar",
      key: "actualizar",
      render: (e, user) => {
        return (
          <>
            {console.log(user, "Estaod..")}
            {user.estado === "Bloqueado" ? (
              <strong style={{ color: "red" }}>BLOQUEADO</strong>
            ) : (
              <>
                <Button
                  onClick={() => {
                    setActualizarUsuario(true);
                    setUsuarioActual(user);
                  }}
                  size="small"
                  style={{ margin: 5 }}
                >
                  Actualizar
                </Button>
                <Popconfirm
                  okText="Bloquear"
                  cancelText="Cancelar"
                  title="¿ESTÁS SEGURO?"
                  onConfirm={() => bloquearUsuario(user._id)}
                >
                  <Button type="danger" size="small" style={{ margin: 5 }}>
                    Bloquear
                  </Button>
                </Popconfirm>
              </>
            )}
          </>
        );
      },
    },
  ];

  const bloquearUsuario = async (_id) => {
    const res = await fetch(
      `https://vcopgg4k05.execute-api.us-east-1.amazonaws.com/prod/api/bloquearUsuario/`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        body: JSON.stringify({
          usuario: _id,
          funcion: "bloquearUsuario",
        }),
      }
    );

    const data = await res.json();
    if (!data.success) {
      message.error(data.msg);
    } else {
      message.success("Usuario bloqueado");
      setUsuarios([]);
      obtenerUsuarios(usuarioActual.usuario);
    }
  };

  const obtenerUsuarios = async (values) => {
    setBuscando(true);
    try {
      const res = await fetch(
        "https://vcopgg4k05.execute-api.us-east-1.amazonaws.com/prod/api/usuarios",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          body: JSON.stringify({ filtro: values }),
        }
      );
      if (res.status === 401) {
        message.error("Tu sesión ha expirado o no tienes permisos");
        // eliminar token
        localStorage.removeItem("token");
        // redireccionar a login
        navigate("/login");

        return;
      }

      const data = await res.json();

      if (data.data && data.data.length > 0) {
        setUsuarios(data.data);
      } else {
        message.error("No se encontraron usuarios");
        setUsuarios([]);
      }
      setBuscando(false);
    } catch (error) {
      console.log(error.name, "yucas..");
    }
  };

  return (
    <div style={{ margin: 10 }}>
      <Drawer
        destroyOnClose
        onClose={() => setActualizarUsuario(false)}
        visible={actualizarUsuario}
      >
        <ActualizaUsuario
          setActualizarUsuario={setActualizarUsuario}
          usuario={usuarioActual}
          obtenerUsuarios={obtenerUsuarios}
          setUsuarios={setUsuarios}
        />
      </Drawer>
      <div
        style={{
          maxWidth: 1200,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h1>Usuarios</h1>
        <Button
          onClick={() => {
            // eliminar token
            localStorage.removeItem("token");
            // redireccionar a login
            navigate("/login");
          }}
        >
          Cerrar Sesión
        </Button>
      </div>

      <div style={{ maxWidth: 1200 }}>
        <Search
          onSearch={obtenerUsuarios}
          enterButton="Buscar Usuario / Email"
          loading={buscando}
        />
      </div>
      <Table
        style={{ maxWidth: 1200 }}
        dataSource={usuarios}
        columns={columns}
      />
    </div>
  );
}

export default Usuarios;
